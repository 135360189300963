var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[(
                        _vm.$store.state.app.user_data &&
                        (_vm.$store.state.app.user_data.role == 'employee' ||
                          _vm.$store.state.app.user_data.role == 'admin')
                      )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Assign To")]),_c('v-select',{attrs:{"placeholder":"None","options":['Employee', 'Channelpartner']},on:{"input":_vm.handleEmployeeOption},model:{value:(_vm.assigntotype),callback:function ($$v) {_vm.assigntotype=$$v},expression:"assigntotype"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('v-select',{attrs:{"label":"fullname","placeholder":"None","options":_vm.userOptions},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                      var fullname = ref.fullname;
                      var profile_image = ref.profile_image;
                      var username = ref.username;
                      var color = ref.color;
return [_c('b-avatar',{attrs:{"src":_vm.getprofileImage(profile_image)}}),_c('span',{class:'font-weight-bolder text-' + color},[_vm._v(" "+_vm._s(fullname)+" ("+_vm._s(username)+")")])]}}]),model:{value:(_vm.assignto),callback:function ($$v) {_vm.assignto=$$v},expression:"assignto"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Priority")]),_c('v-select',{attrs:{"label":"name","placeholder":"None","options":['All', 'High', 'Medium', 'Low']},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Status")]),_c('v-select',{attrs:{"label":"name","placeholder":"None","options":['All', 'Open', 'Pending', 'Complete']},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Task Type")]),_c('v-select',{attrs:{"label":"name","placeholder":"None","options":[
                            'All',
                            'Office Work',
                            'Personal Work',
                            'Social Work' ]},model:{value:(_vm.tasktype),callback:function ($$v) {_vm.tasktype=$$v},expression:"tasktype"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Search")]),_c('b-form-input',{attrs:{"placeholder":"Enter Description","rows":"2"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Select Date")]),_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background-color":"transparent"},attrs:{"value":"","config":{
                            dateFormat: 'd/m/Y',
                            mode: 'range',
                          },"placeholder":"Select Date"},on:{"input":function($event){return _vm.getdata($event)}}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.searchData}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SearchIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Search")])],1)],1)],1)])],1),_c('report-table',{attrs:{"data":_vm.data,"columns":_vm.fields},scopedSlots:_vm._u([{key:"printHeader",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%"}},[_c('h3',{staticStyle:{"margin":"0px"}},[_vm._v("Task Report")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%","flex-direction":"column","align-items":"center"}},[_c('h4',{staticStyle:{"margin":"0px"}},[_vm._v(" Name : "+_vm._s(_vm.assignto && _vm.assignto.fullname ? _vm.assignto.fullname : "All")+" ")]),(_vm.result && _vm.result.length == 2)?_c('h5',{staticStyle:{"margin":"0px"}},[_vm._v(" "+_vm._s(_vm.result[0])+" to "+_vm._s(_vm.result[1])+" ")]):_vm._e()])]},proxy:true}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }