<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col
                        md="4"
                        v-if="
                          $store.state.app.user_data &&
                          ($store.state.app.user_data.role == 'employee' ||
                            $store.state.app.user_data.role == 'admin')
                        "
                      >
                        <b-form-group>
                          <label>Assign To</label>
                          <v-select
                            v-model="assigntotype"
                            placeholder="None"
                            :options="['Employee', 'Channelpartner']"
                            @input="handleEmployeeOption"
                          >
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Name</label>
                          <v-select
                            v-model="assignto"
                            label="fullname"
                            placeholder="None"
                            :options="userOptions"
                          >
                            <template
                              #option="{ fullname, profile_image, username, color }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span :class="'font-weight-bolder text-' + color">
                                {{ fullname }} ({{ username }})</span
                              >
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Priority</label>
                          <v-select
                            v-model="priority"
                            label="name"
                            placeholder="None"
                            :options="['All', 'High', 'Medium', 'Low']"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Status</label>
                          <v-select
                            v-model="status"
                            label="name"
                            placeholder="None"
                            :options="['All', 'Open', 'Pending', 'Complete']"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Task Type</label>
                          <v-select
                            v-model="tasktype"
                            label="name"
                            placeholder="None"
                            :options="[
                              'All',
                              'Office Work',
                              'Personal Work',
                              'Social Work',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Search</label>
                          <b-form-input
                            placeholder="Enter Description"
                            v-model="description"
                            rows="2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            placeholder="Select Date"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                        :disabled="loading"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields">
                <template #printHeader>
                  <div style="display: flex; justify-content: center; width: 100%">
                    <h3 style="margin: 0px">Task Report</h3>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      width: 100%;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <h4 style="margin: 0px">
                      Name :
                      {{ assignto && assignto.fullname ? assignto.fullname : "All" }}
                    </h4>
                    <h5 style="margin: 0px" v-if="result && result.length == 2">
                      {{ result[0] }} to {{ result[1] }}
                    </h5>
                  </div>
                </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BAvatar,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BAvatar,
    GoodTableColumnSearch,
    BFormInput,
  },
  data() {
    return {
      loading: false,
      userOptions: [],
      assignto: "",
      employee: "",
      employeeoption: [],
      subemployee: "",
      subemployeeoption: [],
      assigntoOptions: [],
      startdate: "",
      enddate: "",
      result: "",
      description: "",
      data: "",
      categoryOptions: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      tasktype: "",
      priority: "",
      status: "",
      fields: [
        {
          field: "task",
          label: "Subject",
        },
        {
          field: "date",
          label: "Date",
        },
        {
          field: "enterby",
          label: "Assign By",
        },
        {
          field: "assignto",
          label: "Assign To",
        },
        {
          field: "tasktype",
          label: "Task Type",
        },
        {
          field: "priority",
          label: "Priority",
        },
        {
          field: "deadline",
          label: "Deadline",
        },
        {
          field: "status",
          label: "Status",
        },
        {
          field: "complition",
          label: "Completed",
        },
      ],
      allUser: [],
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      leadCategory: "",
      employeeoption: "",
      assigntotype: "",
      tasktype: "",
    };
  },
  mounted() {
    if (this.$store.state.app.user_data.role == "employee") {
      this.employeeoption = this.$store.state.app.all_users.filter((item) => {
        return item.role == "employee";
      });
      this.cpoptions = this.$store.state.app.all_users.filter((item) => {
        return item.assignto == this.$store.state.app.user_data.id;
      });
    } else if (this.$store.state.app.user_data.role == "admin") {
      this.employeeoption = this.$store.state.app.all_users.filter((item) => {
        return item.role == "employee";
      });
      this.cpoptions = this.$store.state.app.all_users.filter((item) => {
        return item.role == "channelpartner";
      });
    }
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async handleEmployeeOption() {
      this.userOptions = [];
      if (this.assigntotype == "Employee") {
        this.userOptions = [...this.employeeoption];
      } else if (this.assigntotype == "Channelpartner") {
        this.userOptions = [...this.cpoptions];
      }
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result.length > 0 ? this.result[0] : "";
      this.enddate = this.result.length == 2 ? this.result[1] : "";
    },
    async searchData() {
      const data = {
        assignto: this.assignto ? this.assignto.id : "",
        priority: this.priority,
        status: this.status,
        task: this.task,
        tasktype: this.tasktype,
        search: this.search,
        startdate: this.startdate
          ? moment(this.startdate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : "",
        enddate: this.enddate
          ? moment(this.enddate, "DD/MM/YYYY").format("YYYY-MM-DD")
          : "",
      };
      this.loading = true;
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/taskreport`,
      })
        .then((response) => {
          this.data = response.data.data;

          this.data.map((item) => {
            item.enterby = item.enterby ? item.enterby.fullname : "-";
            item.assignto = item.assignto ? item.assignto.fullname : "-";
            item.deadline =
              item.deadline && item.deadline != "0000-00-00"
                ? moment(item.deadline).format("DD/MM/yyyy")
                : "-";
            item.date = item.enterdatetime
              ? moment(item.enterdatetime).format("DD/MM/yyyy")
              : "-";
            item.complition = (item.complition ? item.complition : 0) + " %";
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
